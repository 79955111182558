import React from "react";
import Svgs from "../Svgs";

export default function ActionGroup({isHidden = false, isVertical = false, isLeft=false, isOnSurface=false, buttons = [], className = ""}) {

    function generateGroupClassName() {
        let tempClassName = 'action-group';

        if (isVertical) tempClassName += ' vertical'
        if (isOnSurface) tempClassName += ' surface'

        return tempClassName + ` ${className}`; 
    }

    function generateActionClassName(isDelete=false, isHidden=false) {
        let className = 'action-group-button';
        
        if (isHidden) className += ' action-group-button-hidden';
        if (isDelete) className += ' action-group-button-delete';

        return className; 
    }

    return (
        <>  
            {!isHidden && buttons.filter(button => !button.isHidden).length > 0 && <div 
                className={generateGroupClassName()} 
                data-location={isLeft ? 'left' : 'right'}
            >
                {buttons.map((button, index) => <button 
                    key={index}
                    className={generateActionClassName(button.isDelete, button.isHidden)} 
                    onClick={button.onClick}
                >
                <Svgs name={button.icon}/>
                </button>)}
            </div>}
        </>
    )
}