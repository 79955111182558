import React, {useState, Fragment, useEffect} from "react";

import Svgs from "../Svgs";
import IngredientInputFields from "./IngredientInputFields";
import StepInputFields from "./stepInputFields";

const LISTS_DATA = {
    Ingredients: IngredientInputFields,
    Recipe: StepInputFields
};

const lists = {
    all: Object.entries(LISTS_DATA),
    allNames: Object.keys(LISTS_DATA)
}

lists.first = lists.allNames[0]
lists.last = lists.allNames[lists.allNames.length -1]

export default function ExtendingLists({recipe, readonly, updating}) {
    const [activeListName, setactiveListName] = useState(lists.first);

    useEffect(() => {
        
    }, [activeListName]);

    function nextList(listName) {
        changeActiveList(listName, 1);
    }

    function previousList(listName) {
        changeActiveList(listName, -1);
    }

    function changeActiveList(listName, step){
        setactiveListName(lists.allNames[(lists.allNames.indexOf(listName) + step + lists.allNames.length) % (lists.allNames.length)]);
    }

    return (
        <div className="extendingList">
            {lists.all.map(([name, Component]) => (
                <Fragment key={name}>
                    <div className={"extendingListHeader" + ((activeListName === name) ? ' active' : ' inactive')}>
                        <button onClick={() => previousList(name)} className={(lists.first=== name) ? 'disabled' : ''}><Svgs name={'Previous'} /></button>
                        <h2>{name}</h2>
                        <button onClick={() => nextList(name)} className={(lists.last === name) ? 'disabled' : ''}><Svgs name={'Next'} /></button>
                    </div>

                    <Component recipe={recipe} readonly={readonly} updating={updating} className={"extendingListItem" + ((activeListName === name) ? ' active' : ' inactive')}/>
                </Fragment>
            ))}
        </div>
    );
}