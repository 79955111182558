import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import NavBar from "./NavBar";
import NavRoutes from "./NavRoutes";

export default function Navigation ({ routes }){
    const tabs = routes.filter(route => route.isTab && !route.isCenter);
    const leftTabs = tabs.slice(0, Math.floor(tabs.length / 2));
    const rightTabs = tabs.slice(Math.ceil(tabs.length / 2));
    const centerTab = routes.find(route => route.isCenter);

    return (
        <>
            {tabs.length % 2 === 0 && 
                <Router>
                    <div className={'page'}>
                        <NavRoutes routes={routes}/>
                    </div>

                    <NavBar leftTabs={leftTabs} rightTabs={rightTabs} centerTab={centerTab} />
                </Router>
            }
        </>
    );
}
