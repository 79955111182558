import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";

const DATA = {
    STATES: {
        CLOSED: 'closed',
        OPENING: 'opening',
        OPEN: 'open',
        CLOSING: 'closing'
    },
    STATE_CHANGE_DURATION: 200,
    DEFAULT_QUESTION: "Are you sure?"
}

const modalStyleProps =  {transition: `all ${DATA.STATE_CHANGE_DURATION * 0.001}s`, animationDuration: `${DATA.STATE_CHANGE_DURATION* 0.001}s`}

const Modal = forwardRef(({ children }, ref) => {
    const [modalState, setModalState] = useState(DATA.STATES.CLOSED);
    const [isOpen, setIsOpen] = useState();

    const [onCancel, setOnCancel] = useState(null);
    const [onContinue, setOnContinue] = useState(null);
    const [question, setQuestion] = useState(DATA.DEFAULT_QUESTION)

    const [closeType, setCloseType] = useState(null);

    useImperativeHandle(ref, () => ({ close, open, updateQuestion }));

    useEffect(() => {
        if (isOpen === true) {
            setModalState(DATA.STATES.OPENING);
            setTimeout(() => setModalState(DATA.STATES.OPEN), DATA.STATE_CHANGE_DURATION * 0.8);
        } else if (isOpen === false) {
            setModalState(DATA.STATES.CLOSING);
            setTimeout(() => setModalState(DATA.STATES.CLOSED), DATA.STATE_CHANGE_DURATION * 0.8);
        }
    }, [isOpen]);

    function updateQuestion(question) {
        setQuestion(question);
    }

    function open({question=DATA.DEFAULT_QUESTION, onContinueCallback=null, onCancelCallback=null}) {
        if(isOpen) return;

        setQuestion(question)
        setOnContinue(() => onContinueCallback)
        setOnCancel(() => onCancelCallback);
        setIsOpen(true);
    }

    function close(type) {
        if(!isOpen) return;

        setCloseType(type)
        setIsOpen(false);
    }

    function handleContinue(e) {
        if (onContinue) onContinue(e);
        close('continue');
    }

    function handleCancel(e) {
        if (onCancel) onCancel(e);
        close('cancel');
    }

    function handleClick(e) {
        const $target = e.target;
        const $modal = $target.closest('.modal');
        const $modalButton = $target.closest('.modal-button');

        if ($modal === null) handleCancel();
        else if ($modalButton === null) return;
        else if ($modalButton.classList.contains('modal-continue')) handleContinue();
        else if ($modalButton.classList.contains('modal-cancel')) handleCancel();
    }

    return (
        <div className={`modal-backdrop modal-${modalState}${(closeType) ? ` ${closeType}` : ''}`} onClick={handleClick} style={modalStyleProps}>
            <div className="modal" style={modalStyleProps}>
                <p className="modal-question" style={modalStyleProps}>{question ?? "Are you sure?"}</p>

                {children && <div className="modal-container">
                    {children}
                </div>}
                
                
                <div className="modal-actions">
                    <button className="modal-continue modal-button" style={modalStyleProps}>Yes</button>
                    <button className="modal-cancel modal-button" style={modalStyleProps}>No</button>
                </div>
            </div>
        </div>
    );
});

export default Modal;
