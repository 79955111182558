import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css";
import { writeToStorage, KEYS, readFromStorage } from "../helpers/browserStorageHandeler"
import { THEMES } from "../helpers/colors";

export default function Account(){
    const themeSettings = useRef();
    const [currentTheme, setCurrentTheme] = useState(null);

    useEffect(() => {
        if (!themeSettings) return;

        setCurrentTheme(readFromStorage(KEYS.STYLE))

        function createThemePreview(name) {
            const theme = THEMES[name];
            const $preview = themeSettings.current.getElementsByClassName(`${name}-theme`)[0];

            if(!$preview) return;

            $preview.style.setProperty('--preview-background', theme.background);
            $preview.style.setProperty('--preview-surface', theme.surface);
            $preview.style.setProperty('--preview-main', theme.mainBrand);
        }
    
        Object.keys(THEMES).forEach(name => createThemePreview(name))
    }, []);

    function changeTheme(name) {
        writeToStorage(KEYS.STYLE, name);
        setCurrentTheme(name);
    }

    return (
        <SimpleBar style={{height: `calc(100svh - 2rem - 3.5rem)`}}>
            <div className="list-of-cards list-of-cards-single-row">
                <div key="Theme" id="theme-picker" className="card readonly">
                    <div ref={themeSettings} className="card-content">
                        <h2>Themes</h2>
                        {Object.keys(THEMES).map(name => <div key={`${name}-theme`} className={`${name}-theme${((currentTheme === name) ? " active" : "" )}`} onClick={() => changeTheme(name)}></div>)}
                    </div>
                </div>
            </div>
        </SimpleBar>
    )
}