import React from "react";

export default function EmptyPageModal({message, shouldShow}) {
    return ( 
        <>
            {shouldShow && <div className="emptyPage">
                <p>{message}</p>
            </div>}
        </>
    );
}
