export const THEMES = {
    olive: {
        surface: '#edeae3',
        mainBrand: '#74978e',
        accent: '#4d565d',
        background: '#2a2c35',
        success: '#58a863',
        warning: '#d5982b',
        danger: '#f44336'
    },
    orange: {
        surface: '#F9F9F9',
        mainBrand: '#ED624B',
        accent: '#948C91',
        background: '#343346',
        success: '#7b994f',
        warning: '#f98817',
        danger: '#db190b'
    },
    carrot: {
        surface: '#FCFAFB',
        mainBrand: '#F0911F',
        accent: '#6EBDBE',
        background: '#394165',
        success: '#7b994f',
        warning: '#fb9609',
        danger: '#f44336'
    },
    rose: {
        surface: '#FCFBFB',
        mainBrand: '#B58432',
        accent: '#362129',
        background: '#563641',
        success: '#6ca247',
        warning: '#e9920f',
        danger: '#f44336'
    }
}