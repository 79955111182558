import React from "react";

import NavBarTab from "./NavTab";

export default function NavBarTabs ({tabs, position}){
    return (
        <ul className={'nav-tabs nav-tabs-' + position}>
            {tabs.map((route, index) => (
                <li key={index} className="nav-tab">
                    <NavBarTab tab={route} />
                </li>
            ))}
        </ul>
    );
}