import React, { useRef, useEffect } from "react";

export default function GrowingTextArea({value, onChange, onBlur, placeholder, id, className, tabIndex, name, readonly, refreshCount}){
    const textAreaRef = useRef(null);

    useEffect(() => {refreshHeight(textAreaRef);}, [])

    useEffect(() => {refreshHeight(textAreaRef);}, [refreshCount])

    useEffect(() => {
        if (!textAreaRef) return;

        refreshHeight();
    }, [value, textAreaRef])

    function handleChange(e) {
        if (!textAreaRef) return;
        if (onChange) onChange(e);
    };

    function handleBlur(e){
        if (!textAreaRef) return;
        if (onBlur) onBlur(e);
    }

    function refreshHeight() {
        const pageFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const totalYAxisPadding = getComputedStyle(textAreaRef.current).padding.split('px')[0] * 2;
    
        textAreaRef.current.style.height = "0";
        textAreaRef.current.style.height = ((textAreaRef.current.scrollHeight - totalYAxisPadding) / pageFontSize) + "rem";
    }

    return (
        <textarea 
            id={id ?? null} 
            className={`growing-text-field${(readonly) ? " readonly" : ""} ${className ?? ""}`}
            type="text" 
            placeholder={placeholder} 
            name={name ?? null}
            autoComplete="off" 
            value={(value) ? value.replace(/(\r\n|\n|\r)/g, ' ').replace(/\s+/g,' ').replace(/^ /, '') : ""}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)}
            tabIndex={tabIndex ?? null}
            ref={textAreaRef}
            readOnly={readonly}
        />
    );
}
