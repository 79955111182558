function createEvent(key, data) {
    return new CustomEvent(key, {
        detail: data,
        bubbles: true,
        cancelable: true
    })
}

function sendEvent(key, data) {
    window.dispatchEvent(createEvent(key, data));
}

export { sendEvent };