function shareText(title = "Shared data", text = "") {
    const data = {
        text: `${title}\n\n${text}\nShared via ${window.location.origin}`,
        title: title
    }

    if (navigator.canShare(data)) _share(data);
}

function shareUrl(title = "Share url", url = "") {
    const data = {
        url: url,
        title: title
    }

    if (navigator.canShare(data)) _share(data);
}

function shareData(...files) {
    if (!navigator.canShare({ files })) return;
    _share({ files }, false);
}

function _share(data, canCopy=true) {
    if (navigator.canShare && navigator.share) navigator.share(data).catch(console.error);
    else if (canCopy && typeof navigator.clipboard !== 'undefined' && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(`${data.text}`);
        alert("Text copied");
    }
}

export { shareText, shareData, shareUrl }