import React from "react";
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

import PageTransition from "./PageTransition";

export default function NavRoutes ({ routes }) {
    const location = useLocation();

    return (
        <AnimatePresence mode="sync">
            <Routes location={location} key={location.pathname}>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={<PageTransition className='page-wrapper' motionkey={location.pathname}>{route.component}</PageTransition>} />    
                ))}
                <Route path='*' element={<Navigate to={routes.find(route => route.isDefault).path} state={location.state} />} />
            </Routes>
        </AnimatePresence>
    );
}