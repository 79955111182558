import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import Svgs from "../Svgs";

export default function NavBarTab ({tab, isCenter}){
    const [isPressed, setIsPressed] = useState(false)

    const pressTimer = useRef(null);
    const pressStart = () => {
        setIsPressed(true)
        pressTimer.current = setTimeout(() => setIsPressed(false), 500)
    };

    const pressEnd = () => {
        if (pressTimer.current){
            clearTimeout(pressTimer);
            pressTimer.current = null;
        } 
  
        setTimeout(() => {
            if (isPressed) setIsPressed(false);
        }, 100);
    };

    return (
        <NavLink activeclassname="active" to={tab.path} className={(isCenter ? 'nav-center ' : '') + (isPressed ? 'pressing' : '')} onTouchStart={pressStart} onTouchEnd={pressEnd}>
            <Svgs name={tab.icon} />
            {!isCenter && <p>{tab.name}</p>}
        </NavLink>
    );
}