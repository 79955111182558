import React from "react";

import { ReactComponent as Add } from '../assets/icons/add.svg';
import { ReactComponent as Explore } from '../assets/icons/explore.svg';
import { ReactComponent as Notification } from '../assets/icons/notification.svg';
import { ReactComponent as Account } from '../assets/icons/account.svg';
import { ReactComponent as ShoppingBag } from '../assets/icons/shopping-bag.svg';
import { ReactComponent as Next } from '../assets/icons/next.svg';
import { ReactComponent as Previous } from '../assets/icons/previous.svg';
import { ReactComponent as Image } from '../assets/icons/image.svg';
import { ReactComponent as Camera } from '../assets/icons/camera.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';
import { ReactComponent as Dot } from '../assets/icons/dot.svg';
import { ReactComponent as Duration } from '../assets/icons/duration.svg';
import { ReactComponent as People } from '../assets/icons/people.svg';
import { ReactComponent as Send } from '../assets/icons/send.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as List } from '../assets/icons/list.svg';
import { ReactComponent as ItemList } from '../assets/icons/item-list.svg';
import { ReactComponent as Downloaded } from '../assets/icons/downloaded.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Share } from '../assets/icons/share.svg';
import { ReactComponent as Export } from '../assets/icons/export.svg';
import { ReactComponent as Preview } from '../assets/icons/preview.svg';
import { ReactComponent as Undo} from '../assets/icons/undo.svg';

const svgComponents = {
    Add,
    Explore,
    Notification,
    Account,
    ShoppingBag,
    Next,
    Previous,
    Image,
    Camera,
    Delete,
    Dot,
    Duration,
    People,
    Send,
    Close,
    Edit,
    List,
    ItemList,
    Downloaded,
    Search,
    Share,
    Export,
    Preview,
    Undo
};

export default function Svgs({color, name}){
    const SvgComponent = svgComponents[name];
    
    return <SvgComponent stroke={color || "#000000"} />;
}