import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import initWorkboxRefresh from '@loopmode/cra-workbox-refresh';

import { KEYS, readFromSession, writeToSession } from "./helpers/browserStorageHandeler";

if ('serviceWorker' in navigator && 'PushManager' in window) {
    const installFlow = async (e) => {
        e.preventDefault();
        window.removeEventListener('beforeinstallprompt', installFlow);

        const deferredPrompt = e;
        const $installButton = document.getElementById("installApp");
        
        const showInstallPrompt = (e) => {
            if (e.target.closest(".pwa-popup-prompt")) {
                deferredPrompt.userChoice.then(choice => {
                    if (choice.outcome === 'dismissed') writeToSession(KEYS.IS_INSTALL_CANCELLED, true);
    
                    $installButton.className = "hidden";
                    $installButton.removeEventListener('click', showInstallPrompt);
                });
    
                deferredPrompt.prompt();
            } else {
                writeToSession(KEYS.IS_INSTALL_CANCELLED, true);
                $installButton.className = "hidden";
                $installButton.removeEventListener('click', showInstallPrompt);
            }
        }

        setTimeout(() => {
            $installButton.addEventListener('click', showInstallPrompt);
            $installButton.classList.remove("hidden")

            setTimeout(() => {
                $installButton.className = "hidden";
                $installButton.removeEventListener('click', showInstallPrompt);
                window.removeEventListener('beforeinstallprompt', installFlow);
            }, 10000);
        }, 1000);
    }

    if (readFromSession(KEYS.IS_INSTALL_CANCELLED) === null) 
        window.addEventListener('beforeinstallprompt', installFlow);
}

serviceWorkerRegistration.register({
    onUpdate: registration => initWorkboxRefresh(registration, {
        textContent: 'Restart the app to install the new update',
        className: 'workbox-refresh-button'
    })
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
