class UnitValue {
    #unitInfoList = [
        {
            name: 'gram',
            symbol: 'g',
            conversion: {
                up: {
                    multiplier: 0.001,
                    symbol: 'kg',
                    max: 1000
                },
                down: null
            }
        },
        {
            name: 'kilogram',
            symbol: 'kg',
            conversion: {
                up: null,
                down: {
                    multiplier: 1000,
                    symbol: 'g',
                    min: 1
                }
            }
        },
        {
            name: 'milliliter',
            symbol: 'ml',
            conversion: {
                up: {
                    multiplier: 0.001,
                    symbol: 'l',
                    max: 1000
                },
                down: null
            }
        },
        {
            name: 'liter',
            symbol: 'l',
            conversion: {
                up: null,
                down: {
                    multiplier: 1000,
                    symbol: 'ml',
                    min: 1
                }
            }
        }
    ];

    #unitInfo;

    constructor(value, unit) {
        this.value = parseFloat(value);

        unit = unit.replace(/[^a-z]/gi, '').toLowerCase();
        this.#unitInfo = this.#lookupUnitInfo(unit);
        this.unit = this.#unitInfo?.symbol ?? unit;

        this.#getBaseValue();
    }

    #lookupUnitInfo(unit) {
        return this.#unitInfoList.find(unitInfo => unitInfo.symbol.toLowerCase() === unit || unitInfo.name.toLowerCase() === unit);
    }

    #getBaseValue() {
        if (!this.#unitInfo) return;

        if (this.#unitInfo.conversion.down && this.value) {
            this.value *= this.#unitInfo.conversion.down.multiplier;
            this.unit = this.#unitInfo.conversion.down.symbol;

            this.#unitInfo = this.#lookupUnitInfo(this.unit);
            this.#getBaseValue();
        }
    }

    #normalize() {
        if (!this.#unitInfo) return;

        let updated = false

        if (this.#unitInfo.conversion.up && this.value >= this.#unitInfo.conversion.up.max) {
            this.value *= this.#unitInfo.conversion.up.multiplier;
            this.unit = this.#unitInfo.conversion.up.symbol;

            updated = true;
        } 
        else if (this.#unitInfo.conversion.down && this.value < this.#unitInfo.conversion.down.min) {
            this.value *= this.#unitInfo.conversion.down.multiplier;
            this.unit = this.#unitInfo.conversion.down.symbol;

            updated = true;
        }

        if (!updated) return;

        this.#unitInfo = this.#lookupUnitInfo(this.unit);
        this.#normalize();
    }

    combine(unitValue) {
        if (!(unitValue instanceof UnitValue)) throw new TypeError("Input should be of type UnitValue");
        if(this.unit !== unitValue.unit) throw new Error("Input unit should be of same type as current unit")

        this.value += unitValue.value

        return this;
    }

    multiply(quantity) {
        this.value *= quantity;

        return this;
    }

    deconstruct() {
        this.#normalize();
        return { value: Math.round(this.value * 10) / 10, unit: this.unit };
    }
}

const normalizeValue = (value, unit) => new UnitValue(value, unit).deconstruct();
const tryCombineValues = (value1, value2) => {
    try {
        return new UnitValue(value1.value, value1.unit).combine(new UnitValue(value2.value, value2.unit)).deconstruct();
    } catch {
        return null;
    }
}
const multiplyValues = (value, quantity) => new UnitValue(value.value, value.unit).multiply(quantity).deconstruct()

export { normalizeValue, tryCombineValues, multiplyValues }
