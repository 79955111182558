import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import Modal from "../components/Modals/Modal";
import Svgs from "../components/Svgs";
import EmptyPageModal from "../components/Modals/EmptyPageModal";
import { shareText } from "../helpers/dataShare";
import ActionGroup from "../components/Buttons/ActionGroup";
import { deleteAll, deleteRecipe, getShoppingBagPerIngredient, getShoppingBagPerRecipe, updateQuantity } from "../components/ShoppingBag/ShoppingBagHandler";
import { inBetween } from "../helpers/customMath";
import GrowingTextArea from "../components/GrowingTextArea";

export default function ShoppingLists() {
    const ModalRef = useRef(null);
    const [shoppingBagPerRecipe, setShoppingBagPerRecipe] = useState({});
    const [shoppingBagPerIngredient, setShoppingBagPerIngredient] = useState({})
    const [scrollHeight, setScrollHeight] = useState(null);

    const [listType, setListType] = useState('recipes');

    useEffect(() => {
        function loadShoppingBag() {
            setShoppingBagPerRecipe(getShoppingBagPerRecipe());
            setShoppingBagPerIngredient(getShoppingBagPerIngredient());
        }

        window.addEventListener("reload", loadShoppingBag);
        loadShoppingBag();

        calculateScrollHeight();
        setTimeout(calculateScrollHeight, 200);

        function calculateScrollHeight(){
            const div = document.getElementById('options-shopping-bag');
            if (!div) return;
    
            const height = div.getBoundingClientRect().height + 'px';
            const {marginTop, marginBottom} = window.getComputedStyle(div);
    
            setScrollHeight(`calc(100svh - ${height} - ${marginTop} - ${marginBottom} - 2rem - 3.5rem)`);
        };

        return () => {
            window.removeEventListener("reload", loadShoppingBag);
        }
    }, []);

    function clearAll (e) {
        ModalRef.current.open({
            question: "Are you sure you want to delete all ingredients?", 
            onContinueCallback: async () => {
                deleteAll();
                dispatchEvent(new Event("reload"));
            }
        })   
    }

    function clearRecipe (recipeName) {
        ModalRef.current.open({
            question: "Are you sure you want to delete the ingredients of " + recipeName + "?", 
            onContinueCallback: async () => {
                deleteRecipe(recipeName);
                dispatchEvent(new Event("reload"));
            }
        })   
    }

    function updateRecipeQuantity (recipeName, quantity, e) {
        if (!quantity) quantity = 1;
        else quantity = inBetween(quantity, 1, 20);

        updateQuantity(recipeName, quantity);

        e.target.value = null;

        dispatchEvent(new Event("reload"));
    }

    function shareShoppingList() {
        let text = ""

        if (listType === "ingredients") 
            text = Object.entries(shoppingBagPerIngredient["All ingredients"]).reduce((acc, [key, value]) => `${acc}${key}: ${value.value} ${value.unit}\n`, text);
        else if (listType === "recipes") {
            const totalRecipes = Object.keys(shoppingBagPerRecipe).length

            text = Object.entries(shoppingBagPerRecipe).reduce((recipes, [key, value], index) => 
                `${recipes}${key}\n${Object.entries(value.ingredients).reduce((ingredients, [key, value]) => `${ingredients}${key}: ${value.value} ${value.unit}\n`, text)}${(totalRecipes - 1 === index) ? '' : '\n'}`, text
            );
        }

        shareText("Shopping bag", text);
    }

    return (
        <>
            <EmptyPageModal message="Shopping bag is empty" shouldShow={Object.keys(shoppingBagPerRecipe).length < 1}/>
            <Modal ref={ModalRef}/>
            
            <div className={"list-of-cards-options" + ((Object.keys(shoppingBagPerRecipe).length > 0) ? '' : ' hidden')} id="options-shopping-bag">
                <div className={'listSelector' + ((listType === 'recipes') ? ' active' : '')} onClick={() => setListType('recipes')}><Svgs name='ItemList'/></div>
                <div className={'listSelector' + ((listType === 'ingredients') ? ' active' : '')} onClick={() => setListType('ingredients')}><Svgs name='List'/></div>
                <div className="seperator"/>
                <div onClick={shareShoppingList}><Svgs name='Share'/></div>
                <div className="seperator"/>
                <div className="list-of-cards-options-text delete" onClick={clearAll}>
                    <p>Clear all</p>
                    <Svgs name='Delete'/>
                </div>
            </div>

            <SimpleBar style={{height: scrollHeight}}>
                {Object.keys(shoppingBagPerRecipe).length > 0 && <div className="list-of-cards">
                    {Object.entries(listType === "recipes" ? shoppingBagPerRecipe : shoppingBagPerIngredient).map(([key, value]) => <div key={key} className="card readonly">
                        <div className="card-content">
                            <h2 className="short">{key}</h2>

                            {listType === "recipes" && <ActionGroup isOnSurface buttons={[
                                {
                                    icon: 'Delete',
                                    onClick: () => clearRecipe(key),
                                    isDelete: true
                                }
                            ]}/>}
                            
                            <ul>
                                {listType === "recipes" && <div id="shopping-bag-recipe-quantity">
                                    <h3>For </h3>
                                    <input name={key} type="number" min={1} max={20} placeholder={value.quantity} autoComplete="off" onBlur={(e) => updateRecipeQuantity(key, e.target.value, e)}></input>
                                    <h3> portion{(value.quantity > 1) ? 's' : ''}:</h3>
                                </div>}
                                {listType === "ingredients" && <h3>{Object.keys(value).length} ingredient{(Object.keys(value).length > 1) ? 's' : ''}:</h3>}

                                {Object.entries(value?.ingredients ?? value).map(([key, value]) => <li key={key} className="shopping-bag-recipe-ingredient">
                                    <span><Svgs name='Dot'/></span>
                                    <GrowingTextArea name={key} readonly value={key} />
                                    <div>
                                        <p>{value.value}</p>
                                        <p>{value.unit}</p>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>)}
                </div>}
            </SimpleBar>
        </>
    );
}
