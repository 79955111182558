import React, { useRef, useState, useEffect } from "react";

import Svgs from "../Svgs";

export default function ImageSelector({onChange, value, readonly}) {
    const videoArea = useRef(null);
    const previewArea = useRef(null);
    const imageSearch = useRef(null);

    const [video, setVideo] = useState(null);
    const [imageCaptureObject, setImageCaptureObject] = useState(null);
    const [hasPreview, setHasPreview] = useState(false);
    const [hasCameraPermission, setHasCameraPermission] = useState(true);

    useEffect(() => {
        const src = previewArea.current.src;

        if (value) {
            previewArea.current.src = URL.createObjectURL(value);
            setHasPreview(true);
        }
        else {
            previewArea.current.src = "";
            setHasPreview(false);
        }

        return () => {
            if (src) URL.revokeObjectURL(src);
        }
    }, [value]);

    useEffect(() => {    
        return () => {
            if (video) {
                video.stop();
                setImageCaptureObject(null);
            }
        };
    }, [video]); 

    function handleImage(e) {
        if (e.target.files.length < 1) return;

        const file = e.target.files[0]
        const reader = new FileReader();

        reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const blob = new Blob([arrayBuffer], { type: file.type });
              
            handleNewImage(blob)
        };
      
        reader.readAsArrayBuffer(file);
    }

    async function openCamera(e) {
        const config = {
            audio: false, 
            video: {
                facingMode: 'environment',
                aspectRatio: { exact: 4 / 3 }
            }
        }

        navigator.mediaDevices.getUserMedia(config)
            .then(startVideo)
            .catch(() => setHasCameraPermission(false));
    }

    function startVideo(stream) {
        const videoStream = stream.getVideoTracks()[0];

        setVideo(videoStream);
        setImageCaptureObject(new ImageCapture(videoStream))
        
        videoArea.current.srcObject = stream;
        videoArea.onloadedmetadata = videoArea.play;
    }

    async function captureImage(e) {
        try {
            handleNewImage(await imageCaptureObject.takePhoto());
        } catch (err) {
            console.error(err);
        }
    }

    function cancelImageCapture(e) {
        setVideo(null);
    }

    function handleNewImage(blob) {
        setVideo(null);

        previewArea.current.src = URL.createObjectURL(blob);

        setHasPreview(true);
        videoArea.current.srcObject = null;

        onChange(blob);
    }

    function deletePreviewImage(e) {
        previewArea.current.src = "";
        imageSearch.current.value = null;

        setHasPreview(false);

        onChange(null);
    }

    return (
        <div id="image-form">
            {!readonly && <>
                <div id="image-form-actions" className={(imageCaptureObject ? "hidden " : "") + ((hasPreview) ? "preview " : "") + "action-group vertical"}>
                    <input id="image-search" type="file" accept="image/jpeg,image/png" onChange={handleImage} ref={imageSearch}/>
                    <label htmlFor="image-search" className="action-group-button"><Svgs name="Image"/></label>

                    <button id="image-camera-toggle" className={((hasCameraPermission) ? "" : " nopermission")} disabled={!hasCameraPermission} onClick={openCamera}><Svgs name="Camera"/></button>
                    {hasPreview && <button id="image-preview-delete" className={"action-group-button action-group-button-delete"} onClick={deletePreviewImage}><Svgs name="Delete"/></button>}
                </div>

                <div id="image-form-camera" className={imageCaptureObject ? "" : "hidden"}>
                    <video ref={videoArea} autoPlay muted playsInline/>
                    <button id="image-camera-capture" onClick={captureImage}/>
                    <button id="image-camera-cancel" onClick={cancelImageCapture}><Svgs name="Close"/></button>
                </div>
            </>}
            
            <div id="image-form-preview" className={(imageCaptureObject || !hasPreview) ? "hidden" : ""}>
                <img ref={previewArea} alt="food" title="food"/>
            </div>

            {readonly && !value && <div id="image-form-preview-default">
                <div><Svgs name="Explore"/></div>
            </div>}
        </div>
    );
}