import { sendEvent } from "./eventHandeler";
import { DELAY_LONG, throttle } from "./functionHandeler";

const KEYS = {
    IS_DARK_THEME: 'is-dark-theme',
    STYLE: 'style',
    SHOPPING_BAG: 'shopping-bag-v2',
    IS_INSTALL_CANCELLED: 'is-install-cancelled',
    NEW_RECIPE_VERSION: 'new-recipe-version',
    NEW_RECIPE_VERSION_IMAGE: 'new-recipe-version-image'
}

function writeToStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    sendEvent(key, value);
}

function readFromStorage(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}

function writeToSession(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

function readFromSession(key) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}

const writeToSessionOptimized = throttle((key, value) => writeToSession(key, value), DELAY_LONG);

export { writeToStorage, readFromStorage, writeToSession, readFromSession, writeToSessionOptimized, KEYS };