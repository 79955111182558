import React, { useEffect, useState } from "react";

import recipeDB from "../../helpers/localDatabases/recipeDB";
import GrowingTextArea from "../GrowingTextArea";
import { KEYS, writeToSessionOptimized } from "../../helpers/browserStorageHandeler";
import { capitalizeFirstLetter } from "../../helpers/stringManipulation";

export default function StepInputFields({recipe, readonly, className, updating}){
    const [recipeSteps, setRecipeSteps] = useState([]);
    const [growingTextAreaRefreshCount, setGrowingTextAreaRefreshCount] = useState(0);
    const [currentRecipeId, setCurrentRecipeId] = useState(null);

    useEffect(() => {
        if (!className.includes("inactive")) setGrowingTextAreaRefreshCount(count => (count + 1) % 2);
    }, [className]);

    useEffect(() => {
        if (!(recipe && recipe.id) || recipe.id === currentRecipeId) return;

        setCurrentRecipeId(recipe.id);

        if (readonly || updating) prepareSteps(recipe.recipeInstructionSteps, readonly);
        else recipeDB.getRecipeSteps(recipe.id).then(prepareSteps);

        function prepareSteps(steps, isPreview = false) {
            const cleanedSteps = steps.filter(step => step.text !== '').map(step => ({text: step.text}));
            if (!isPreview && cleanedSteps.length < 1) cleanedSteps.push({ text: "" });

            setRecipeSteps([...cleanedSteps]);
        }
    }, [recipe, readonly, currentRecipeId, updating]);

    useEffect(() => {
        if (readonly) return;
        if (recipeSteps.length < 1) return;

        if (updating) {
            recipe.recipeInstructionSteps = recipeSteps;
            writeToSessionOptimized(KEYS.NEW_RECIPE_VERSION, recipe);
        } 
        else recipeDB.updateRecipeSteps(currentRecipeId, recipeSteps);

        if (recipeSteps[recipeSteps.length - 1].text !== "") setRecipeSteps(steps => [...steps, { text: "" }]);
    }, [recipe, recipeSteps, readonly, updating, currentRecipeId]);

    function handleStepTextUpdate(index, e) {
        handleStepUpdate(index, 'text', capitalizeFirstLetter(e.target.value));
    }

    function handleStepUpdate(index, key, value) {
        let newSteps = [...recipeSteps];
        newSteps[index][key] = value;

        setRecipeSteps(newSteps);
    }

    function handleStepsTextBlur(index, e) {
        if (index === recipeSteps.length - 1 || e.target.value.trim().length !== 0) return;

        setRecipeSteps(steps => { 
            const newSteps = [...steps];
            newSteps.splice(index, 1);
            return newSteps;
        });
    }

    function handleLaneNumberBlur(index, e) {
        let newIndex = e.target.value;

        if (newIndex === "") return;
        e.target.value = "";

        newIndex--;

        const max = recipeSteps.length - 2;
        const min = 0;
        
        if (newIndex < min) newIndex = min;
        else if(newIndex > max) newIndex = max;

        setRecipeSteps(steps => {
            const newSteps = [...steps];
            newSteps.splice(newIndex, 0, newSteps.splice(index, 1)[0]);
            return newSteps;
        });

        setGrowingTextAreaRefreshCount(count => (count + 1) % 2)
    }

    function handleLaneNumberKeyDown(e) {
        if (e.keyCode === 13){
            e.preventDefault();
            document.activeElement.blur();
            return;
        }

        const key = e.key;
        const filteredKey = key.replace(/[^0-9]/g, '');

        if (key.length > 1) return;
        if (filteredKey.length < 1 || e.target.value.length > 2) e.preventDefault();
    }

    return (
        <>
            {recipeSteps.length > 0 && recipeSteps.map((step, index) => (
                <div className={"step" + ((className) ? " " + className : "")} key={`text-temp-${index}`}>
                    <div className="laneIndicator">
                        <input
                            type="text"
                            name={`laneNumber_${index}`}
                            placeholder={index + 1 + "."}
                            readOnly={readonly}
                            onKeyDown={handleLaneNumberKeyDown}
                            onBlur={(e) => handleLaneNumberBlur(index, e)}
                            inputMode="numeric"
                        />
                    </div>

                    <GrowingTextArea 
                        className="text"
                        name={`text_${index}`}
                        value={step.text}
                        tabIndex={index + 1}
                        readonly={readonly}
                        onChange={(e) => handleStepTextUpdate(index, e)}
                        onBlur={(e) => handleStepsTextBlur(index, e)}
                        placeholder="Next step..."
                        refreshCount={growingTextAreaRefreshCount}
                    />
                </div>
            ))}
        </>
    );
}