import { motion } from 'framer-motion';

export default function PageTransition({children, className, motionkey}){
    return (
        <motion.div
            key={motionkey}
            className={className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
        >
            {children}
        </motion.div>
    );
}
