import React from "react";

import NavBarTabs from "./NavBarTabs";
import NavBarTab from "./NavTab";

export default function NavBar({ leftTabs, rightTabs, centerTab }) {
    const cancelEvent = (e) => {
        e.preventDefault();
    }

    return (
        <>
            {leftTabs.length === rightTabs.length && 
                <div className="nav-bar" onClick={cancelEvent} onContextMenu={cancelEvent}>
                    <NavBarTabs tabs={leftTabs} position={'left'}/>

                    {centerTab && <NavBarTab tab={centerTab} isCenter={true} />}

                    <NavBarTabs tabs={rightTabs} position={'right'}/>
                </div>
            }
        </>
    );
}
