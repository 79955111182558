import './assets/styling/css/reset.css';
import './assets/styling/css/style.css';

import React, {useRef} from 'react';
import Theme from './components/Theme';

import Navigation from './components/Navigation/Navigation';
import RecipeLists from './pages/RecipeLists';
import AddRecipe from './pages/AddRecipe';
import Recipe from './pages/Recipe';
import ShoppingLists from './pages/ShoppingLists';
import Account from './pages/Account';
import Notifications from './pages/Notifications';

export default function App() {
    const routes = useRef([
        { path: '/recipes/lists', component: <RecipeLists />, isTab: true, icon: 'Search', name: "Recipes", isDefault: true },
        { path: '/shopping-lists', component: <ShoppingLists />, isTab: true, icon: 'ShoppingBag', name: "Groceries" },
        { path: '/recipes/add', component: <AddRecipe />, isTab: true, icon: 'Add', name: "Add recipe", isCenter: true },
        { path: '/notifications', component: <Notifications />, isTab: true, icon: 'Notification', name: "Alerts" },
        { path: '/account', component: <Account />, isTab: true, icon: 'Account', name: "Profile" },
        { path: '/recipes/:id', component: <Recipe />, name: "Recipe"}
    ])

    return (
        <Theme>
            <Navigation routes={routes.current} />
        </Theme>
    );
}