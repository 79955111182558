const DELAY_LONG = 500;
const DELAY_SHORT = 100; 

function debounce(fn, delay=DELAY_SHORT) {
    let timeout = null;

    return (...params) => {
        return new Promise((resolve, reject) => {
            if (timeout) clearTimeout(timeout);

            timeout = setTimeout(() => {
                try {
                    const result = fn.apply(this, params);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }, delay);
        });
    }
}

function throttle(fn, delay=DELAY_SHORT){
    let timeoutId;   
    let mostRecentParams;
    let mostRecentContext;

    return (...params) => {
        return new Promise((resolve, reject) => {
            mostRecentParams = params;
            mostRecentContext = this;

            if (timeoutId) return;

            timeoutId = setTimeout(() => {
                try {
                    const result = fn.apply(mostRecentContext, mostRecentParams);
                    resolve(result);
                } catch (error) {
                    reject(error);
                } finally {
                    timeoutId = null;
                }
            }, delay);
        });
    }
}

export { debounce, throttle, DELAY_SHORT, DELAY_LONG }